import { useEffect, useMemo } from 'react';
import { template } from 'lodash';
import ReportHoneTable from 'presentation/components/ReportTable/ReportHoneTable';

import { HoneReportTypes } from 'domain/models';
import { AnalyticsEvents, trackEvent } from 'lib/analytics';
import * as ReportUtils from 'lib/reportUtils';
import { useActiveReportStore } from 'hooks/useActiveReportStore';
import { shallow } from 'zustand/shallow';
import { formatDate } from 'lib/utils';

interface Props {
  columnHeaderTitle: string;
  report: HoneReportSummary;
  customHeaders?: string[];
  hasCharts: boolean;
  selectedReportType: HoneReportType;
  selectedReportId: string;
}

function ReportDataTable({
  columnHeaderTitle,
  report,
  customHeaders = [],
  hasCharts,
  selectedReportType,
  selectedReportId,
}: Props): JSX.Element {
  const { currentReport, smoothingEnabled } = useActiveReportStore(
    state => ({
      currentReport: state.currentReport,
      status: state.status,
      smoothingEnabled: state.smoothingEnabled,
    }),
    shallow
  );

  const reportType = currentReport ? currentReport.type : '';
  const reportTimeframe = currentReport ? currentReport.timeframe : '';
  const reportDates = useMemo(() => {
    return currentReport ? currentReport.dates : [];
  }, [currentReport]);
  const reportSections = useMemo(() => {
    return currentReport ? currentReport.sections : [];
  }, [currentReport]);

  const eventParams = useMemo(() => {
    if (currentReport) {
      return {
        type: reportType,
        timeframe: reportTimeframe,
        id: report.id,
      };
    } else {
      return {
        type: '',
        timeframe: '',
        id: '',
      };
    }
  }, [report.id, currentReport, reportType, reportTimeframe]);

  useEffect(() => {
    trackEvent(AnalyticsEvents.ReportViewed, eventParams);
  }, [eventParams]);

  const memo = useMemo(() => {
    let dates;

    if (currentReport) {
      try {
        dates = reportDates.map(({ end }) => formatDate(end));
      } catch {
        dates = reportDates.map(({ start }) => start);
      }

      const rows = ReportUtils.collectRows(Array.from(reportSections) as any);
      // use custom headers if they exist for the column
      const headers = ['Week Ending', ...dates].map((header, index) => {
        const customHeader = customHeaders[index];

        // allow template custom headers
        if (customHeader) {
          const compiled = template(customHeader);
          return compiled({ value: header });
        }

        return header;
      });

      // allow customizing all report types
      const defaultReportStyles = [
        { currentReport: 'title', width: '20%' },
        ...dates.map((date, index) => ({ currentReport: `data.${index}`, width: '200px' })),
      ];
      const cashFlowReportStyles = [
        { currentReport: 'title', width: '60%' },
        ...dates.map((date, index) => ({ currentReport: `data.${index}`, width: '200px' })),
      ];
      const columnStyles = {
        [HoneReportTypes.CashFlow]: cashFlowReportStyles,
        [HoneReportTypes.BalanceSheet]: cashFlowReportStyles,
        [HoneReportTypes.PLComparison]: defaultReportStyles,
        [HoneReportTypes.PLDetail]: defaultReportStyles,
        [HoneReportTypes.IncomeStatement]: defaultReportStyles,
        [HoneReportTypes.PrimeCost]: defaultReportStyles,
      };
      const columns = columnStyles[reportType as HoneReportType];

      return { rows, headers, columns };
    } else {
      return {};
    }
  }, [currentReport, report, reportDates, reportSections, reportType, columnHeaderTitle, customHeaders]);

  return (
    <>
      <div className="main-content-container">
        {currentReport && (
          <ReportHoneTable
            smoothingEnabled={smoothingEnabled}
            report={currentReport}
            headers={memo.headers as string[]}
            hasCharts={hasCharts}
            selectedReportType={selectedReportType}
            selectedReportId={selectedReportId}
          />
        )}
      </div>
    </>
  );
}

export default ReportDataTable;
