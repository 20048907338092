import { HttpClient, HttpStatusCode } from 'data/protocols/http';

export abstract class BaseHttpService<T> {
  constructor(
    protected readonly url: string,
    protected readonly httpClient: HttpClient<T>
  ) {}

  protected async request<U>(
    method: 'get' | 'post' | 'put' | 'delete' | 'patch',
    body?: unknown,
    params?: U,
    options?: { signal: AbortSignal }
  ): Promise<T | HttpError> {
    const httpResponse = await this.httpClient.request(
      {
        url: this.url,
        method: method,
        body: body,
        params: params,
      },
      options
    );

    switch (httpResponse.statusCode) {
      case HttpStatusCode.ok:
        return httpResponse.body as T;
      case HttpStatusCode.clientClosedRequest:
        return [] as T;
      case HttpStatusCode.serverError:
        throw new Error((httpResponse as HttpError).body.error);
      default:
        throw new Error((httpResponse as ErrorResponse).body);
    }
  }
}
