import { createContext, useContext } from 'react';
import type { PropsWithChildren } from 'react';
import { trackError } from 'lib/analytics';
import { useLocationsStore } from '../hooks/useLocationsStore';
import { doPost, reportingOrigin } from 'lib/utils';

export interface Context {
  saveLocation: (location: HoneLocation, userId: string) => Promise<any>;
}

const HoneLocationsContext = createContext<Context | undefined>(undefined);

export default function HoneLocationsProvider(props: PropsWithChildren<unknown>): JSX.Element {
  const saveLocation = async (location: HoneLocation, userId: string) => {
    try {
      const response = await doPost(`${reportingOrigin()}/setLocation`, { userId, location });
      return response;
    } catch (error) {
      trackError({ error: error as Error });
      return error;
    }
  };

  return (
    <HoneLocationsContext.Provider
      value={{
        saveLocation,
      }}
      {...props}
    />
  );
}

export function useHoneLocations(): Context {
  const locations = useContext(HoneLocationsContext);

  if (!locations) throw new Error('You must call useHoneLocations from within HoneLocationsProvider tree');

  return locations;
}
