import React from 'react';
import { useLocationsStore } from '../../../hooks/useLocationsStore';
import { shallow } from 'zustand/shallow';
import Select from 'react-select';
import { Control, RegisterOptions, Controller } from 'react-hook-form';

interface LocationSelectorProps {
  control: Control<any>;
  name: string;
  rules: RegisterOptions;
}

function LocationSelector(props: LocationSelectorProps) {
  const { allLocations, status } = useLocationsStore(
    state => ({ allLocations: state.allLocations, status: state.status }),
    shallow
  );

  const options = React.useMemo(() => {
    return allLocations.map(option => ({
      label: option.name,
      value: option.id,
    }));
  }, [JSON.stringify(allLocations)]);

  return (
    <Controller
      name={props.name}
      control={props.control}
      rules={props.rules}
      render={({ field, formState: { errors, isSubmitSuccessful } }) => (
        <>
          <Select
            {...field}
            ref={ref => {
              if (isSubmitSuccessful) {
                ref?.clearValue();
              }
            }}
            menuPlacement="auto"
            isLoading={status === 'loading'}
            options={options}
          />
          {errors[props.name] && (
            <p style={{ color: 'red' }}>{(errors[props.name]!.message as string) ?? 'Required'}</p>
          )}
        </>
      )}
    />
  );
}

export default LocationSelector;
