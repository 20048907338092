import { IconCollapse, IconExpand } from './Icons';

interface ExpandCollapseProps {
  expand: boolean;
  handleExpand: () => void;
}

export function ExpandCollapse({ expand, handleExpand }: ExpandCollapseProps): JSX.Element {
  return (
    <div className="table_header_button_group expand-collapse">
      <span>{expand ? 'COLLAPSE' : 'EXPAND'}</span>
      <button onClick={handleExpand} className={'collapse-btn'}>
        {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
        {/* @ts-ignore */}
        {expand ? <IconExpand className={'icon'} /> : <IconCollapse className={'icon'} />}
      </button>
    </div>
  );
}
