import { memo, SyntheticEvent, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import classNames from 'classnames';
import { format, parse } from 'date-fns/fp';

// import { ReportViewContext } from "main/factories/pages/report-view/ReportViewContext";

import { IconChart, IconMoney, IconPercentage, IconRefresh, IconSmooth } from './Icons';
import { HoneReportTypes, ReportView } from 'domain/models';
import FloatingAdminMenu from 'presentation/pages/report-view/components/FloatingAdminMenu/FloatingAdminMenu';
import toast from 'react-hot-toast';
import {
  abilityCan,
  BALANCE_SHEET_PRESETS,
  currencyFormatter2Decimals,
  isPeriodReport,
  percentageFormatter,
  showToast,
  TOAST_ERROR,
} from 'lib/utils';
import qs from 'querystring';
import * as Sentry from '@sentry/react';
import { ExpandCollapse } from './ExpandCollapse';

import { performReportUpdate, useActiveReportStore } from 'hooks/useActiveReportStore';
import { useQueryState } from 'hooks/useQueryState';
import useActiveActions from 'hooks/useActiveActions';
import { useReportsStore } from 'hooks/useReportsStore';
import { useLocationsStore } from 'hooks/useLocationsStore';

import { useHoneLocationUsers } from 'components/HoneLocationUsers';
import {
  extractReportIds,
  HoneAbilityActions,
  HoneAbilitySubjects,
  PeriodStartEndDates,
  ReportColumnTypes,
} from '@hone-automation/common';

import { Report } from 'lib/honeTableUtils';
import { differenceInSeconds, parseJSON } from 'date-fns';
import { ExportReport } from './ExportReport';
import Tooltip from 'rsuite/Tooltip';
import Whisper from 'rsuite/Whisper';
import { useNavigate, useParams } from 'react-router-dom';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { getHeaderFormattedText } from 'presentation/utils';
import { getPrintedHeaderDate } from 'lib/reportUtils';

interface HoneTableHeaderProps {
  generatePdf: () => void;
  selectedReportType: HoneReportType;
  selectedReportId: string;
  currentLocationId?: string;
  collapseTable: any;
  allRowsExpanded: any;
  onHandleCollapseExpand: () => any;
  refreshingReport: boolean;
  setRefreshingReport: (nextState: boolean) => void;
  reportDatesHeaders: string[];
}

function HoneTableHeader({
  generatePdf,
  selectedReportType,
  selectedReportId,
  currentLocationId,
  collapseTable,
  allRowsExpanded,
  onHandleCollapseExpand,
  refreshingReport,
  setRefreshingReport,
  reportDatesHeaders,
}: HoneTableHeaderProps): JSX.Element {
  const { enableNewPlReportModal } = useFlags();
  const { reportId, reportType } = useParams<{ reportId: string; reportType: string }>();
  const [chartCollapsed] = useQueryState('chartCollapsed', 'true');
  const navigateTo = useNavigate();
  const currentLocation = useLocationsStore(state => state.currentLocation);
  const selectedReport = useReportsStore(state => state.selectedReport);
  // const { reportViewState, setReportViewState } = useContext(ReportViewContext);
  const [reportViewState, setReportViewState] = useState({});
  const { activeActions, toggleAction } = useActiveActions();
  const showPercentageValues = activeActions.includes('lines');
  const showMoneyValues = activeActions.includes('bars');
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const dateRange = urlParams.get('dateRange');
  const [querySmoothing, setQuerySmoothing] = useQueryState('smoothing');
  const templateId = urlParams.get('templateId');
  const smoothingEnabled = querySmoothing === 'true';
  const compareLocationsEnabled = urlParams.get('compareLocations') === 'true';
  const storeSmoothing = useActiveReportStore(state => state.smoothingEnabled);
  const toggleSmoothing = useActiveReportStore(state => state.toggleSmoothing);
  const currentReport = useActiveReportStore(state => state.currentReport);

  const { currentLocationAbilities } = useHoneLocationUsers();
  const isBookkeeper = abilityCan(
    currentLocationAbilities,
    HoneAbilityActions.read,
    HoneAbilitySubjects.Bookkeeper_Controls
  );

  const handleRefreshReport = async (e: SyntheticEvent<HTMLButtonElement>) => {
    try {
      const target = e.currentTarget;
      // allow only every two minutes
      const updatedAt = currentReport?.updatedAt ? currentReport?.updatedAt : selectedReport?.updatedAt;
      const difference = updatedAt ? differenceInSeconds(new Date(), parseJSON(updatedAt)) : 0;

      const refreshTimeAllowed = 60 * 5;
      if (difference < refreshTimeAllowed) {
        showToast(`Report can be refreshed again in ${refreshTimeAllowed - difference} seconds`, TOAST_ERROR);
        return;
      }

      if (target) {
        target.disabled = true;
      }

      if (!selectedReportId) {
        return;
      }
      setRefreshingReport(true);
      const periodStartEndDates = currentReport?.dates;
      const extractedReportIds = extractReportIds(periodStartEndDates!);

      const reportIds = extractedReportIds.length > 0 ? extractedReportIds : [selectedReportId];
      await performReportUpdate(selectedReport!, reportIds, enableNewPlReportModal);

      if (target) {
        target.disabled = false;
      }
    } catch (e) {
      Sentry.captureException(e);
      console.error(e);
      toast.error('Error refreshing report');
    } finally {
      setRefreshingReport(false);
    }
  };

  const handleSmoothingToggle = () => {
    setQuerySmoothing((!(querySmoothing === 'true')).toString());
    toggleSmoothing();
  };

  useEffect(() => {
    if (smoothingEnabled && !storeSmoothing) {
      toggleSmoothing();
    }
  }, [smoothingEnabled, storeSmoothing, toggleSmoothing]);

  const handleClickTab = useCallback(
    (option: ReportView) => {
      if (option === ReportView.money) {
        toggleAction('bars');
        setReportViewState({
          ...reportViewState,
          showMoneyValues: !showMoneyValues,
        });
        return;
      }
      if (option === ReportView.percentage) {
        toggleAction('lines');
        setReportViewState({
          ...reportViewState,
          showPercentageValues: !showPercentageValues,
        });
        return;
      }
    },
    [toggleAction]
  );

  const isBalanceSheetPreset = Object.keys(BALANCE_SHEET_PRESETS).includes(reportId!);

  const showViewToggles = ![
    HoneReportTypes.CashFlow,
    HoneReportTypes.BalanceSheet,
    HoneReportTypes.ApAging,
    '',
  ].includes(selectedReportType);

  const getReportChildren = (reports: NestedHoneReportSection[], flatArray: Report[] = []): Report[] => {
    for (const report of reports) {
      const maskedTitle = Array(report.level).fill(' * ').join('') + report.title;
      const maskedPercentageTitle = Array(report.level).fill(' * ').join('') + '% OF ' + report.title;

      flatArray.push({
        ...report,
        ...report.data?.map(data => (showMoneyValues ? currencyFormatter2Decimals(data?.amount) : '')),
        title: maskedTitle,
      });

      if (report.title !== '' && showPercentageValues) {
        flatArray.push({
          ...report,
          ...report.data?.map(data => percentageFormatter(data?.perc || 0)),
          title: maskedPercentageTitle,
        });
      }

      if (report.children) {
        getReportChildren(report.children, flatArray);
      }
    }
    return flatArray;
  };

  const csvReport = useMemo(() => {
    const reportName = `${selectedReport?.startDateText} - ${selectedReport?.endDateText}`;
    const headers =
      reportDatesHeaders?.map((date, index) => {
        return {
          label: date,
          key: index.toString(),
        };
      }) || [];

    return {
      filename: `Hone Report: ${currentLocation?.name} ${selectedReport?.title} - ${reportName}.csv`,
      headers: [{ label: 'Title', key: 'title' }, { label: 'GL Code', key: 'glCode' }, ...headers],
      data: currentReport ? getReportChildren(currentReport?.sections) : [],
    };
  }, [
    getReportChildren,
    selectedReport,
    selectedReport?.endDateText,
    selectedReport?.startDateText,
    selectedReport?.title,
  ]);

  const periodReport = isPeriodReport(selectedReport);

  const shouldShowSmoothing =
    ![HoneReportTypes.BalanceSheet, HoneReportTypes.CashFlow, HoneReportTypes.ApAging].includes(selectedReportType) &&
    !periodReport;

  const toggleChartCollapse = () => {
    const nextState = chartCollapsed === 'true' ? 'false' : 'true';
    const newQueries: { chartCollapsed?: string; templateId?: string | null } = {
      chartCollapsed: nextState,
      templateId: templateId,
    };
    if (!templateId) delete newQueries.templateId;

    navigateTo(
      {
        pathname: `/app/location/${currentLocationId}/report/${reportType}`,
        search: qs.stringify(newQueries),
      },
      { replace: true }
    );
  };

  const isEligibleForAdminControls = !enableNewPlReportModal && isBookkeeper && !isBalanceSheetPreset && !periodReport;
  const showRefreshButton = !periodReport;
  const showAdminControls = isEligibleForAdminControls || enableNewPlReportModal;

  // Feature Flag is on, Report is ApAging and link is configured
  const isApAgingReport = selectedReportType === HoneReportTypes.ApAging;
  const showPayBillsButton = isApAgingReport && currentLocation?.paymentLink;

  const handlePaymentLinkClick = () => {
    window.open(currentLocation?.paymentLink, '_blank');
  };

  return (
    <div className="screen-controls-container">
      <div
        /* style={{
        marginBottom: columns ? -60 : 24,
      }} */
        className={classNames('hone-report-table--controls', {
          'hone-report-table--controls-admin': isBookkeeper,
        })}
      >
        <div className="left">
          {!allRowsExpanded && !isApAgingReport && (
            <ExpandCollapse expand={collapseTable} handleExpand={onHandleCollapseExpand} />
          )}
          {!isBalanceSheetPreset && (
            <div className="table_header_button_group">
              <span>{showViewToggles ? 'Controls' : 'Refresh'}</span>
              <div className="table_header_button_group__buttons">
                {shouldShowSmoothing && (
                  <Whisper
                    placement="top"
                    controlId="control-id-hover"
                    trigger="hover"
                    speaker={<Tooltip>Smooth report</Tooltip>}
                  >
                    <button
                      data-testid="toggle-smoothing"
                      title="Toggle smoothing"
                      className={classNames('table_header_button', { table_header_button__active: smoothingEnabled })}
                      type="button"
                      onClick={handleSmoothingToggle}
                    >
                      <IconSmooth />
                    </button>
                  </Whisper>
                )}
                {showRefreshButton && (
                  <Whisper
                    placement="top"
                    controlId="control-id-hover"
                    trigger="hover"
                    speaker={<Tooltip>Refresh report</Tooltip>}
                  >
                    <button
                      disabled={refreshingReport}
                      className="table_header_button"
                      title="Refresh report"
                      type="button"
                      onClick={handleRefreshReport}
                    >
                      <IconRefresh />
                    </button>
                  </Whisper>
                )}
                {showViewToggles && (
                  <>
                    <Whisper
                      placement="top"
                      controlId="control-id-hover"
                      trigger="hover"
                      speaker={<Tooltip>{!showMoneyValues ? 'Show' : 'Hide'} money values</Tooltip>}
                    >
                      <button
                        className={classNames('table_header_button', { table_header_button__active: showMoneyValues })}
                        type="button"
                        data-testid="report-toggle-money-value-button"
                        title="Toggle money value"
                        onClick={() => handleClickTab(ReportView.money)}
                      >
                        <IconMoney />
                      </button>
                    </Whisper>
                    <Whisper
                      placement="top"
                      controlId="control-id-hover"
                      trigger="hover"
                      speaker={<Tooltip>{!showPercentageValues ? 'Show' : 'Hide'} percentages</Tooltip>}
                    >
                      <button
                        data-testid="report-toggle-percentage-value-button"
                        className={classNames('table_header_button', {
                          table_header_button__active: showPercentageValues,
                        })}
                        type="button"
                        title="Toggle percentages"
                        onClick={() => handleClickTab(ReportView.percentage)}
                      >
                        <IconPercentage />
                      </button>
                    </Whisper>
                    <Whisper
                      placement="top"
                      controlId="control-id-hover"
                      trigger="hover"
                      speaker={<Tooltip>{chartCollapsed === 'true' ? 'Show' : 'Hide'} chart</Tooltip>}
                    >
                      <button
                        data-testid="report-toggle-chart-value-button"
                        className={classNames('table_header_button', {
                          table_header_button__active: chartCollapsed === 'false',
                        })}
                        type="button"
                        title="Toggle Chart"
                        onClick={toggleChartCollapse}
                      >
                        <IconChart />
                      </button>
                    </Whisper>
                  </>
                )}
              </div>
            </div>
          )}
          <div className="hone-table-header-spacer"></div>
          {showPayBillsButton && (
            <div className="table_header_button_group">
              <span>External</span>
              <div className="table_header_button_group__buttons">
                <Whisper
                  placement="top"
                  controlId="control-id-hover"
                  trigger="hover"
                  speaker={<Tooltip>Pay Bills</Tooltip>}
                >
                  <button
                    disabled={refreshingReport}
                    className="table_header_button pay-bills_button"
                    title="Pay bills"
                    type="button"
                    onClick={handlePaymentLinkClick}
                  >
                    PAY BILLS
                  </button>
                </Whisper>
              </div>
            </div>
          )}
          {showAdminControls && (
            <FloatingAdminMenu
              selectedReportId={selectedReportId}
              locationId={currentLocationId}
              selectedReportType={selectedReportType}
            />
          )}
          <ExportReport csvReport={csvReport} pdfReport={generatePdf} />
        </div>
      </div>
    </div>
  );
}

export default memo(HoneTableHeader);
