import { Suspense, useEffect, useState, ChangeEvent, useMemo, useCallback } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import * as Sentry from '@sentry/react';

import usePrevious from 'hooks/usePrevious';

import { useFlags } from 'launchdarkly-react-client-sdk';

import { useReportsStore } from 'hooks/useReportsStore';

import { cloneDeep, isEmpty, uniqBy } from 'lodash';
import Loading from 'components/Loading';
import PDFObject from 'lib/components/PDFObject';
import ReportDataTable from './components/ReportDataTable';
import { HoneReportFormats, HoneReportTypes, MENU_ICONS } from '../../../constants';
import { trackError } from 'lib/analytics';
import {
  abilityCan,
  BALANCE_SHEET_PRESETS,
  doPost,
  FIVE_SECONDS,
  isPeriodReport,
  reportingOrigin,
  reportTitleToUrl,
  showToast,
  sortReportTitles,
  TOAST_ERROR,
} from 'lib/utils';

import LocationsAutocomplete from 'presentation/components/LocationsAutocomplete/LocationsAutocomplete';
import ReportGraph from './components/ReportGraph/ReportGraph';

import toast from 'react-hot-toast';
import { HoneReportStatuses, HoneReportTimeframes } from 'domain/models';
import { formatDate } from 'lib/utils';
import { fetchReportById, useActiveReportStore } from 'hooks/useActiveReportStore';
import { shallow } from 'zustand/shallow';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useLocationsStore } from 'hooks/useLocationsStore';

import { useQueryState } from 'hooks/useQueryState';
import { useReportGraphStore } from 'hooks/useReportGraphStore';

import { ReportNotFoundError } from 'domain/errors/report-not-found-error';
import { QueryStatus } from '@tanstack/react-query';
import qs from 'querystring';
import { useHighlightReportSelector } from 'hooks/useHighlightReportSelector';
import ReportDatesModal from 'components/ReportDatesModal';
import classNames from 'classnames';
import ModalCustomDateRange from './ModalCustomDateRange';
import {
  ComparisonRequest,
  ComparisonType,
  HoneAbilityActions,
  HoneAbilitySubjects,
  ReportRequest,
} from '@hone-automation/common';
import { useHoneLocationUsers } from '../../../components/HoneLocationUsers';

import KSHeader from '../../components/KSHeader';

interface FallbackProps {
  error: Error;
  resetErrorBoundary: (...args: Array<unknown>) => void;
}

function ErrorFallback({ error }: FallbackProps) {
  return (
    <div role="alert">
      <p>This report is being updated, please try again in a few minutes.</p>
      <pre style={{ display: process.env.NODE_ENV === 'development' ? 'block' : 'none' }}>{error.message}</pre>
    </div>
  );
}

interface Props {
  reports: Map<string, HoneReportSummary[]>;
  columnHeaderTitle?: string;
  customHeaders?: string[];
  status: QueryStatus;
}

const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

function ReportViewNoPl({ reports, columnHeaderTitle = '', customHeaders, status }: Props): JSX.Element {
  const { currentLocationAbilities } = useHoneLocationUsers();

  const { isHovered } = useHighlightReportSelector();

  const [allReportsSelected, setAllReportsSelected] = useQueryState<boolean>('allReports', false);

  const { enableReportDateRange, enableNewPlReportModal, enableV3Location } = useFlags();

  const [auditMode] = useQueryState('audit');

  const { currentLocationId } = useLocationsStore(
    state => ({ currentLocationId: state.currentLocationId, currentLocation: state.currentLocation }),
    shallow
  );

  const location = useLocation();
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const { locationId, reportId, reportType } = useParams<{
    locationId: string;
    reportId: string;
    reportType: string;
  }>();

  const originalReportType = reportType;

  const navigateTo = useNavigate();
  const isPLReport = ['income-statement', 'monthly', 'weekly'].includes(reportType!);

  const [chartCollapsed, setChartCollapsed] = useQueryState('chartCollapsed', 'false');

  // useEffect(() => {
  //   if (isPLReport) {
  //     setChartCollapsed("true");
  //   }
  // }, [reportType, isPLReport]);

  const [, setDateRange] = useQueryState<string>('dateRange');
  const isBalanceSheetPreset = Object.keys(BALANCE_SHEET_PRESETS).includes(reportId!);

  const reportsError = useReportsStore(state => state.error);
  const { selectedReport, setOpenDateRangeModal } = useReportsStore(
    state => ({
      selectedReport: state.selectedReport,
      setOpenDateRangeModal: state.setOpenDateRangeModal,
    }),
    shallow
  );

  const isYtd = selectedReport?.timeframe === HoneReportTimeframes.YTDMonthly;

  const { reportStatus, allCategories, getGroupReport, setYearPlotted, rangeNoData, setStatus, currentReport } =
    useActiveReportStore(
      state => ({
        setStatus: state.setStatus,
        rangeNoData: state.rangeNoData,
        reportStatus: state.status,
        allCategories: state.allCategories,
        getGroupReport: state.getGroupReport,
        setYearPlotted: state.setYearPlotted,
        currentReport: state.currentReport,
      }),
      shallow
    );

  const [statusUpdates, setStatusUpdates] = useState<Record<string, string>>({});

  const allReports = reports.get(isPLReport ? 'pl' : reportType!) || [];
  const titles = uniqBy(allReports, 'title');

  const uniqueTitles = sortReportTitles(titles);

  const [titleFilter] = useQueryState<string>('type', reportTitleToUrl(uniqueTitles[0]?.title || ''));
  const [templateId] = useQueryState<string>('templateId');

  const filteredTitleFilter = templateId
    ? reportTitleToUrl(uniqueTitles.find(props => props.templateId === templateId)?.title || '')
    : titleFilter.split('&')[0];

  let filteredReports = allReports;
  if (isPLReport && titleFilter) {
    filteredReports = filteredReports.filter(({ title, timeframe }) => {
      if ((timeframe.includes('YTD') && reportType === 'income-statement') || timeframe.toLowerCase() === reportType) {
        return reportTitleToUrl(title) === filteredTitleFilter;
      }
      return false;
    });
  }

  const prevReportType = usePrevious(reportType);
  const dateRange = urlParams.get('dateRange');

  useEffect(() => {
    rangeNoData && navigateTo(`/app/location/${currentLocationId}/report/${reportType}`, { replace: true });
  }, [rangeNoData]);

  const oldReportId = usePrevious(reportId);

  useEffect(() => {
    if (!isYtd) return;
    if (!selectedReport) return;
    const yearToPlot = Number(selectedReport.startDateText.split('-')[0]);
    setYearPlotted(yearToPlot);
  }, [selectedReport]);

  const totalParsed = urlParams.get('total');
  const differenceParsed = urlParams.get('difference');
  const validateParsed = urlParams.get('validate');

  useEffect(() => {
    if (dateRange || (isBalanceSheetPreset && oldReportId !== reportId)) {
      let type: 'P&L Comparison' | 'Balance Sheet' = 'P&L Comparison';
      let [startDate, endDate] = urlParams.get('dateRange')?.split(',') || [];
      let limit;
      if (isBalanceSheetPreset) {
        type = 'Balance Sheet';
        const preset = BALANCE_SHEET_PRESETS[reportId as keyof typeof BALANCE_SHEET_PRESETS];
        startDate = preset.startDateText;
        endDate = preset.endDateText;
        limit = preset.limit;
      }

      const totalParsed = urlParams.get('total');
      const differenceParsed = urlParams.get('difference');
      const comparisonType = urlParams.get('comparisonType');
      const comparisonPeriods = urlParams.get('comparisonPeriods');
      const timeframe = urlParams.get('timeframe');

      const comparison: ComparisonRequest = {
        comparisonType: comparisonType as ComparisonType,
        comparisonPeriods: Number(comparisonPeriods),
      };
      (async () => {
        const reportPayload: ReportRequest = {
          type,
          locations: [String(currentLocationId) || ''],
          templateId: templateId,
          startDate,
          endDate,
          total: totalParsed === 'true',
          difference: differenceParsed === 'true',
          limit,
        };

        if (comparisonType) {
          reportPayload.comparison = comparison;
        }

        const response = await getGroupReport(reportPayload);

        if (response === 'error') {
          navigateTo(`/app/location/${currentLocationId}`, { replace: true });
        }
      })();
    }
  }, [dateRange, isBalanceSheetPreset, reportId, oldReportId, totalParsed, differenceParsed]);

  useEffect(() => {
    if (!validateParsed) return;

    (async () => {
      const parsedReportId = selectedReport && selectedReport?.id;
      useActiveReportStore.setState({ status: 'loadingDateRange', refreshingReport: false });
      const report = allReports.find(report => report.id === parsedReportId);
      const response = fetchReportById(report);
      if (validateParsed) {
        await toast.promise(
          response,
          {
            loading: 'Validating report, will take a few seconds...',
            success: 'Report, successfully validated',
            error: 'Error validating report',
          },
          {
            style: {
              maxWidth: 'max(50vw, 350px)',
            },
          }
        );
      }
    })();
  }, [validateParsed]);

  useEffect(() => {
    const clonedAllReports = cloneDeep(allReports);
    if (!reportId && (reportType === 'ap-aging' || reportType === 'cash-flow')) {
      const reportsByType = reports.get(reportType);
      const report = reportsByType && reportsByType[0];
      report && fetchReportById(report);
      return;
    }
    const parsedReportId = reportId && reportId.includes('?') ? reportId.split('?')[0] : reportId;
    const report = clonedAllReports.find(report => {
      return report.id === parsedReportId;
    });

    report && fetchReportById(report);
  }, [reportId, allReports, reportType]);

  useEffect(() => {
    if (!allReports.length) return;
    let selectedReport: HoneReportSummary | undefined;
    if (reportType) {
      if (reportType === 'balance-sheet' && typeof reportId === 'undefined') {
        navigateTo(`/app/location/${locationId}/report/balance-sheet/6-months`, { replace: true });
        return;
      }

      selectedReport = reports.get(reportType)![0];
      if (prevReportType !== reportType) {
        setYearPlotted(new Date().getFullYear());
      }

      if (reportId) {
        if (Object.keys(BALANCE_SHEET_PRESETS).includes(reportId)) {
          useReportsStore.setState({ selectedReport });
          return;
        }

        const parsedReportId = reportId.includes('?') ? reportId.split('?')[0] : reportId;
        if (templateId && !dateRange) {
          selectedReport = reports.get(reportType)!.find(report => {
            return report.id === parsedReportId && report.templateId === templateId;
          });
        } else {
          const reportFound = reports.get(reportType)!.find(report => report.id === parsedReportId);

          if (!dateRange && !reportFound) {
            Sentry.captureException(`Report not found ${reportId}`);
            setTimeout(() => {
              showToast(
                "The report you are trying to access isn't available. Please click on another report to access the data you want.",
                TOAST_ERROR
              );
            }, 1);

            return;
          }
          selectedReport = reportFound;
        }
      } else {
        if (titleFilter && !dateRange) {
          selectedReport = reports
            .get(reportType)!
            .find(report => filteredTitleFilter === reportTitleToUrl(report.title));
        }
      }
      if (!selectedReport && !dateRange) {
        try {
          setTimeout(() => {
            showToast(
              "The report you are trying to access isn't available. Please click on another report to access the data you want.",
              TOAST_ERROR,
              FIVE_SECONDS
            );
          }, 1);

          const lookingForId = reportId || titleFilter;
          throw new ReportNotFoundError(lookingForId);
        } catch (error) {
          Sentry.captureException(error);
          navigateTo(`/app/location/${currentLocationId}`);
        }
      }

      if (useReportsStore.getState().selectedReport?.id !== selectedReport?.id) {
        useReportsStore.setState({ selectedReport });
      }
      if (selectedReport?.id) {
        useActiveReportStore.setState({ currentReportId: selectedReport.id });
      }
    }
  }, [allReports, reports, reportId, reportType]);

  const setReportStatus = (reportId: string, status: string) => {
    return doPost(`${reportingOrigin()}/reports/status`, { reportId, status });
  };

  const handleReportStatusChange = (reportId: string, status: string) => {
    const updater = setReportStatus(reportId, status).catch(error => {
      trackError({ error: error as Error });
      // reset back to previous status
      if (selectedReport) {
        setStatusUpdates({ ...statusUpdates, ...{ [reportId]: selectedReport.status } });
      }
    });
    toast.promise(
      updater,
      {
        loading: 'Updating...',
        success: `Updated report status to ${status}`,
        error: 'Error updating report status',
      },
      {
        style: {
          maxWidth: 'max(50vw, 350px)',
        },
        success: {
          duration: 4000,
        },
      }
    );
    setStatusUpdates({ ...statusUpdates, ...{ [reportId]: status } });
  };

  const years = useMemo(
    () =>
      allReports.map(report => ({
        period: new Date(report.endDate).getFullYear(),
        reportId: report.id,
      })),
    [allReports]
  );

  const handleReportChange = (newReportId: string) => {
    if (newReportId === 'custom_range') {
      setOpenDateRangeModal(true);
      return;
    }

    setStatus('loading');
    setDateRange(undefined);

    const yearPlotted = years.find(year => year.reportId === newReportId);
    yearPlotted?.period && setYearPlotted(yearPlotted?.period);

    const { dateRange, total, difference, ...queryParams } = qs.parse(location.search.replace('?', ''));
    const newQueries = { ...queryParams };

    if (templateId) {
      newQueries.templateId = templateId;
    }
    useReportsStore.setState({ selectedReport: undefined });
    useActiveReportStore.setState({ currentReport: undefined });
    navigateTo({
      pathname: `/app/location/${currentLocationId}/report/${reportType}/${newReportId}`,
      search: qs.stringify(newQueries),
    });
  };

  const handleTitleFilterChange = async (e: ChangeEvent<HTMLSelectElement>) => {
    const templateId = e.target.value;
    const isPeriodReport = e.target.options[e.target.selectedIndex].dataset.period;
    let timeframe = e.target.options[e.target.selectedIndex].dataset.timeframe;
    timeframe = timeframe!.includes('ytd') ? 'income-statement' : timeframe;
    if (dateRange && !isPeriodReport) {
      const totalParsed = urlParams.get('total');
      const differenceParsed = urlParams.get('difference');
      const newDateRange = urlParams.get('dateRange');

      const queryParams = qs.parse(location.search.replace('?', ''));

      const newQueries = {
        ...queryParams,
        dateRange: newDateRange,
        templateId,
        total: totalParsed,
        difference: differenceParsed,
      };

      navigateTo({
        pathname: `/app/location/${currentLocationId}/report/${originalReportType}`,
        search: qs.stringify(newQueries),
      });

      const response = await getGroupReport({
        baseId: '',
        type: 'P&L Comparison',
        locations: currentLocationId ? [String(currentLocationId)] : [],
        templateId: String(templateId),
        startDate: newDateRange?.split(',')[0],
        endDate: newDateRange?.split(',')[1],
        total: totalParsed === 'true',
        difference: differenceParsed === 'true',
      });

      if (response === 'error') {
        navigateTo(`/app/location/${currentLocationId}`, { replace: true });
      }
      useReportsStore.setState({ selectedReport: undefined });
      return;
    }
    if (templateId) {
      const { dateRange, total, difference, ...queryParams } = qs.parse(location.search.replace('?', ''));
      let newQueries: any = { ...queryParams, templateId };
      if (!isPeriodReport) {
        newQueries = { ...newQueries, dateRange, total, difference };
      }
      useReportsStore.setState({ selectedReport: undefined });
      navigateTo({
        pathname: `/app/location/${currentLocationId}/report/${timeframe}`,
        search: qs.stringify(newQueries),
      });
      return;
    }
  };

  // Disable smoothing for Balance Sheet and Cash Flow
  useEffect(() => {
    if (selectedReport && selectedReport?.type === HoneReportTypes.IncomeStatement)
      useReportGraphStore.setState({ extraReportId: undefined });
    if (
      selectedReport &&
      (selectedReport.type === HoneReportTypes.BalanceSheet || selectedReport.type === HoneReportTypes.CashFlow)
    ) {
      useActiveReportStore.setState({ smoothingEnabled: false });
    }
  }, [selectedReport]);

  const hasCharts =
    !isEmpty(allCategories) && selectedReport && ['P&L Comparison', 'Income Statement'].includes(selectedReport?.type);

  const showCharts = chartCollapsed === 'false' && auditMode !== 'true' && hasCharts && reportType !== 'ap-aging';

  const enableDateRangeBasedOnType = selectedReport?.type === HoneReportTypes.PLComparison;

  const getFilteredReportsOptions = () => {
    if (enableReportDateRange && enableDateRangeBasedOnType && selectedReport?.type === HoneReportTypes.PLComparison) {
      return [{ id: 'custom_range', startDateText: 'Custom Range', endDateText: '' }, ...filteredReports];
    }

    if (selectedReport?.type === HoneReportTypes.BalanceSheet) {
      return [
        ...Object.entries(BALANCE_SHEET_PRESETS as unknown as Record<string, string>).map(([id, startDateText]) => ({
          id,
          startDateText,
          endDateText: '',
        })),
        ...filteredReports,
      ];
    }

    return filteredReports;
  };
  const summaryReport = useReportGraphStore(state => state.summaryReport);

  const graphWidth = useMemo(() => {
    if (isYtd) {
      return 1200;
    }

    return 60 + 175 * ((summaryReport?.reportLabels || []).length ?? 3);
  }, [isYtd, selectedReport?.id, summaryReport?.reportLabels]);

  const filteredReportsOptions = getFilteredReportsOptions();

  const getSelectedReportId = useCallback(() => {
    if (reportId) return reportId.includes('?') ? reportId.split('?')[0] : reportId;
    if (dateRange) return 'custom_range';

    if (status === 'pending') return 'loading';

    if (selectedReport?.type === HoneReportTypes.PLComparison) {
      // check if index exists in array of reports
      return filteredReportsOptions[0].id;
    }

    const index = filteredReportsOptions.findIndex(report => report.id === selectedReport?.id);
    if (index > -1) {
      return filteredReportsOptions[index].id;
    }
    return filteredReportsOptions[0].id;
  }, [selectedReport]);

  if (reportsError) {
    return (
      <div className="report-view add-padding">
        <div className="report-view--header">
          <div />
          <div className="report-view--header__location">
            <LocationsAutocomplete />
          </div>
        </div>
        <div className="report-view-container h-100 center">{reportsError}</div>
      </div>
    );
  }

  if (reportStatus === 'loadingDateRange' || !selectedReport || !currentReport) {
    return (
      <div className="h-100">
        <Loading />
      </div>
    );
  }

  const showAllButton =
    selectedReport?.type === HoneReportTypes.PLComparison && selectedReport?.timeframe === HoneReportTimeframes.Weekly;

  const canReadBookkeeperControls = abilityCan(
    currentLocationAbilities,
    HoneAbilityActions.read,
    HoneAbilitySubjects.Bookkeeper_Controls
  );

  const Icon: any = selectedReport && selectedReport.type in MENU_ICONS ? MENU_ICONS[selectedReport.type] : null;
  const rTitle: string =
    selectedReport && typeof selectedReport.type === 'string' && selectedReport.type === 'P&L Comparison'
      ? 'P&L'
      : selectedReport.type;

  console.log({ selectedReport });

  return (
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <div className="report-view">
        <KSHeader />
        <div className="report-view--header add-padding">
          <div className="report-view--header__item">
            <span className="header-icon header-icon-bottom ">
              <Icon />
            </span>
            <div className="report-view-header-title">{rTitle}</div>
            {selectedReport && (
              <div className="report-view-container-title">
                {isPLReport && uniqueTitles.length > 1 && (
                  <select value={templateId} onChange={handleTitleFilterChange}>
                    {uniqueTitles.map((props, index) => {
                      const { type, title, templateId, timeframe } = props;
                      const _isPeriodReport = isPeriodReport(props);
                      return (
                        <option
                          value={templateId}
                          data-timeframe={timeframe.toLowerCase()}
                          data-period={_isPeriodReport}
                          key={`${type}-${index}`}
                        >
                          {title}
                        </option>
                      );
                    })}
                  </select>
                )}
              </div>
            )}

            {reportType !== 'ap-aging' ? (
              <ReportDatesModal
                key={dateRange}
                uniqueTitles={uniqueTitles}
                timeframe={selectedReport.timeframe}
                selectedReportTitle={selectedReport.title}
                onLoadMore={() => setAllReportsSelected('true')}
                selectedOption={getSelectedReportId()}
                onReportChange={handleReportChange}
                options={filteredReportsOptions}
              />
            ) : (
              <div
                className={classNames('reports-selector-container', {
                  hovered: isHovered,
                })}
              >
                <select value={getSelectedReportId()} onChange={e => handleReportChange(e.currentTarget.value)}>
                  {filteredReportsOptions.length > 0 ? (
                    filteredReportsOptions.map(({ id, startDateText, endDateText }) => {
                      return (
                        <option value={id} key={id}>
                          {id === 'custom_range' &&
                            `Custom Range ${dateRange ? '[' + dateRange?.split('&')[0] + ']' : ''} `}
                          {Object.keys(BALANCE_SHEET_PRESETS).includes(id)
                            ? BALANCE_SHEET_PRESETS[id as keyof typeof BALANCE_SHEET_PRESETS].label
                            : !['custom_range'].includes(id) &&
                              `${formatDate(new Date(startDateText + 'T00:00:00'))} - ${formatDate(
                                new Date(endDateText + 'T00:00:00')
                              )}`}
                        </option>
                      );
                    })
                  ) : (
                    <option value="loading">Loading...</option>
                  )}
                </select>
                {showAllButton && (
                  <button
                    disabled={allReportsSelected}
                    className="btn btn-secondary btn-secondary-small"
                    onClick={() => setAllReportsSelected('true')}
                  >
                    All
                  </button>
                )}
                <ModalCustomDateRange uniqueTitles={uniqueTitles} />
              </div>
            )}
          </div>
          <div className="report-view--header__item">
            <div>
              {canReadBookkeeperControls && selectedReport && (
                <div>
                  <select
                    value={statusUpdates[selectedReport.id] || selectedReport.status}
                    onChange={e => handleReportStatusChange(selectedReport.id, e.currentTarget.value)}
                  >
                    {Object.values(HoneReportStatuses).map((status, index) => (
                      <option value={status} key={index}>
                        {status}
                      </option>
                    ))}
                  </select>
                </div>
              )}
            </div>
            {!enableV3Location && (
              <div className="report-view--header__location">{!isMobile && <LocationsAutocomplete />}</div>
            )}
          </div>
        </div>
        {selectedReport && (
          <div className="report-view-container">
            {showCharts && (
              <ReportGraph
                graphWidth={graphWidth}
                isYTD={isYtd}
                selectedReport={selectedReport}
                reports={filteredReports}
                extraReportDataSummary={null}
                timeframe={selectedReport.timeframe}
                onReportChange={handleReportChange}
              />
            )}
            <Suspense
              fallback={
                <div className="h-100 center">
                  <Loading />
                </div>
              }
            >
              {selectedReport.format === HoneReportFormats.PDF && <PDFObject url={selectedReport.dataLoc} />}
              {selectedReport.format === HoneReportFormats.JSON && (
                <ReportDataTable
                  report={selectedReport}
                  columnHeaderTitle={columnHeaderTitle}
                  customHeaders={customHeaders || []}
                  hasCharts={!!showCharts}
                  selectedReportType={selectedReport.type}
                  selectedReportId={selectedReport.id}
                />
              )}
            </Suspense>
          </div>
        )}
      </div>
    </ErrorBoundary>
  );
}

export default ReportViewNoPl;
