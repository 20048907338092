import React, { useEffect, useState } from 'react';
import { Option } from 'types';
import { IconDown, IconUp, IconCalendar } from 'components/Icons';
import getWeek from 'date-fns/getWeek';

import './KSDropdown.scss';

import classNames from 'classnames';

interface CustomDropdownProps {
  options: Option[];
  onSelect: (option: Option) => void;
  placeholder?: string;
  icon?: React.ReactNode;
  label?: string;
  value: Option | null;
  period?: 'Week' | 'Month' | 'Quarter' | 'Year' | 'Year to Date' | undefined;
  theme?: 'white' | 'grey';
  disabled?: boolean;
  enableScrollReload?: boolean;
  enableMinWidth?: boolean;
  reloadNewItemsFn?: () => void;
}

export const formatRangeValues = (range: string | null | undefined, period: string | undefined) => {
  if (!period) return range;
  const parts = (range && range.split(' - ')) || [];
  if (parts.length == 0) return `Date not provided`;

  const startDate = new Date(parts[0]);
  if (parts.length === 2) {
    const endDate = new Date(parts[1]);
    /* Combining the dates into a single string
         Same year in both parts returns the date in format DATE - DATE, 2023
      */
    if (startDate.getFullYear === endDate.getFullYear) {
      // Same month in both parts returns the date in format JUNE 1 - 30, 2023
      if (startDate.getMonth() === endDate.getMonth()) {
        return `${startDate.toLocaleDateString('en-US', { month: 'short', day: 'numeric' })} - ${endDate.toLocaleDateString(
          'en-US',
          { day: 'numeric' }
        )}, ${startDate.getFullYear()}`;
      } else {
        return `${startDate.toLocaleDateString('en-US', { month: 'short', day: 'numeric' })} - ${endDate.toLocaleDateString(
          'en-US',
          { month: 'short', day: 'numeric' }
        )}, ${startDate.getFullYear()}`;
      }
    } else {
      // full formatted dates
      const formattedStartDate = startDate.toLocaleDateString('en-US', {
        month: 'short',
        day: 'numeric',
        year: 'numeric',
      });
      const formattedEndDate = endDate.toLocaleDateString('en-US', {
        month: 'short',
        day: 'numeric',
        year: 'numeric',
      });
      return `${formattedStartDate} - ${formattedEndDate}`;
    }
  }
  return startDate.toLocaleDateString('en-US', {
    month: 'short',
    day: 'numeric',
    year: 'numeric',
  });
};

export const fornatRangeUnits = (
  range: string | null | undefined,
  period: string | undefined,
  className: string | undefined
) => {
  if (!range) return ``;
  const parts = (range && range.split(' - ')) || [];
  if (parts.length == 0) return `Date not provided`;
  const startDate = new Date(parts[0]);
  let unit = ``;

  switch (period) {
    case 'Week':
      unit = `W${getWeek(startDate, { weekStartsOn: 1 }).toString().padStart(2, '0')}`;
      break;

    case 'Month':
      unit = `M${(startDate.getMonth() + 1).toString().padStart(2, '0')} `;
      break;

    case 'Year':
      unit = `${startDate.getFullYear()}`;
      break;
    case 'Quarter':
      unit = `Q${Math.ceil((startDate.getMonth() + 1) / 3)}`;
      break;
  }
  if (unit ?? className) {
    return <div className={className}>{unit}</div>;
  }
  return unit;
};

const CustomDropdown: React.FC<CustomDropdownProps> = ({
  label,
  options,
  value,
  icon,
  theme = 'white',
  period,
  placeholder,
  enableScrollReload = false,
  enableMinWidth = false,
  disabled = false,
  onSelect,
  reloadNewItemsFn,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const refContainer = React.useRef<HTMLDivElement>(null);
  const refContent = React.useRef<HTMLDivElement>(null);
  const refItemsContainer = React.useRef<HTMLDivElement>(null);
  const isDisabled = disabled || (!period && options.length < 2);
  const isGreyTheme = theme === 'grey' || period;

  const handleToggle = () => {
    setIsOpen(!isOpen);
  };

  const handleOptionClick = (option: Option) => {
    setIsOpen(false);
    onSelect(option);
  };

  useEffect(() => {
    const handleClickOutside = (event: Event) => {
      if (refContainer.current && !refContainer.current.contains(event.target as Node)) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  });

  const handleScroll = (ev: any) => {
    if (
      refItemsContainer.current?.clientHeight &&
      refItemsContainer.current?.scrollTop &&
      enableScrollReload &&
      reloadNewItemsFn
    ) {
      const isEndScroll =
        refItemsContainer.current.scrollTop + refItemsContainer.current.clientHeight >=
        refItemsContainer.current.scrollHeight;
      if (isEndScroll) {
        reloadNewItemsFn();
      }
    }
  };

  return (
    <div className="ks-custom-dropdown" ref={refContainer}>
      {label && <div className="ks-dropdown-label">{label}</div>}
      <div className={classNames('ks-dropdown-content', { 'enable-min-width': enableMinWidth })} ref={refContent}>
        <button
          className={classNames('ks-dropdown-toggle', {
            'enable-min-width': enableMinWidth,
            period: period,
            disabled: isDisabled,
            'grey-theme': isGreyTheme,
          })}
          type="button"
          onClick={handleToggle}
          disabled={isDisabled}
        >
          {period && (
            <div className="ks-dropdown-toggle-icon">
              <IconCalendar />
            </div>
          )}
          {period && fornatRangeUnits(value ? value.label : placeholder, period, 'ks-dropdown-range-units')}
          {formatRangeValues(value ? value.label : placeholder, period)} {icon}
          {options.length > 1 && <span className="ks-arrow">{isOpen ? <IconUp /> : <IconDown />}</span>}
        </button>

        {isOpen && (
          <div
            className={classNames('ks-dropdown-menu', {
              'enable-min-width': enableMinWidth,
              'not-label': !label,
              period: period,
            })}
            onScroll={handleScroll}
            ref={refItemsContainer}
          >
            {options.map((option, idx) => (
              <div
                key={`${option.value}_${idx}`}
                className={classNames({ period: period })}
                onClick={() => handleOptionClick(option)}
              >
                {period && fornatRangeUnits(option.label, period, 'ks-dropdown-range-units')}
                {formatRangeValues(option.label, period)}
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default CustomDropdown;
