import { useEffect, useState } from 'react';
import type { FormEvent } from 'react';
import { useHoneLocationUsers } from './HoneLocationUsers';
import { cloneDeep } from 'lodash';

import 'react-datepicker/dist/react-datepicker.css';

import {
  showToast,
  dismissToast,
  TOAST_DEFAULT,
  TOAST_SUCCESS,
  TOAST_ERROR,
  HALF_MINUTE,
  TEN_SECONDS,
} from 'lib/utils';
import { emailReportMetrics } from '../hooks/useReportsStore';

interface Props {
  locationId: string | undefined;
  reportId: string | undefined;
  onSuccess: () => void;
}

const TOAST_EMAIL_REPORT_METRICS_TEXT = 'Emailing report metrics, will take a moment to complete.';

function EmailReportMetricsForm({ locationId, reportId, onSuccess }: Props): JSX.Element {
  const { allLocationUsers } = useHoneLocationUsers();
  const [recipients, setRecipients] = useState<HoneLocationUser[]>([]);
  const [message, setMessage] = useState('');

  const handleFormSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    onSuccess();

    const data = new FormData(e.currentTarget);
    const finalRecipients = recipients.map(locationUser => {
      return { email: locationUser.email };
    });
    const otherEmail = data.get('otherEmail') !== '' ? (data.get('otherEmail') as string) : undefined;
    const otherEmailCheckbox = data.get('otherEmailCheckbox');

    // Add other email to final recipients
    if (otherEmailCheckbox && otherEmail) {
      finalRecipients.push({ email: otherEmail });
    }

    const toastId = showToast(TOAST_EMAIL_REPORT_METRICS_TEXT, TOAST_DEFAULT, HALF_MINUTE);
    if (reportId && finalRecipients && message) {
      emailReportMetrics(reportId, finalRecipients, message)
        .then(() => {
          dismissToast(toastId);
          showToast('Successfully initiated emailing of report metrics', TOAST_SUCCESS, TEN_SECONDS);
        })
        .catch(error => {
          dismissToast(toastId);
          showToast(`Error attempting to email report metrics: ${error.toString()}`, TOAST_ERROR, HALF_MINUTE);
        });
    } else {
      dismissToast(toastId);
      showToast(`Invalid  input`, TOAST_ERROR, HALF_MINUTE);
    }
  };

  useEffect(() => {
    const newRecipients: any = cloneDeep(allLocationUsers);

    if (locationId) {
      setRecipients(newRecipients);
    }
  }, [locationId]);

  const handleToggleEmailUser = async (e: FormEvent<HTMLInputElement>) => {
    const email = e.currentTarget.value;
    if (e.currentTarget.checked) {
      const newRecipient = allLocationUsers.find(locationUser => locationUser.email === email);
      const newRecipients: any = cloneDeep(recipients);
      newRecipients.push(newRecipient);
      setRecipients(newRecipients);
    } else {
      const newRecipients: any = cloneDeep(recipients).filter(elem => elem.email !== email);
      setRecipients(newRecipients);
    }
  };

  const handleMessageChange = (e: FormEvent<HTMLTextAreaElement>) => {
    const { value } = e.currentTarget;
    setMessage(value);
  };

  const isEmailInRecipients = (email: string) => {
    return recipients.find(recipient => recipient.email === email) !== undefined;
  };

  return (
    <form className="EmailReportMetricsForm" onSubmit={handleFormSubmit}>
      <h3 className="mb-8">Email Report Metrics</h3>

      <div className="mt-8 mb-4">
        <label className="label-light" htmlFor="recipients">
          Recipients
        </label>

        <table className="mb-8">
          {allLocationUsers &&
            allLocationUsers.map((user: HoneLocationUser) => (
              <tr id={user?.email} className="">
                <td>
                  <input
                    className="mx-2"
                    type="checkbox"
                    checked={isEmailInRecipients(user?.email)}
                    value={user?.email}
                    onClick={e => handleToggleEmailUser(e)}
                  />
                </td>
                <td>
                  <span className="mr-3">{user?.email}</span>
                  <span className="label-small">({user?.role})</span>
                </td>
              </tr>
            ))}

          <tr>
            <td>
              <input className="mt-3 mx-2" type="checkbox" id="otherEmailCheckbox" name="otherEmailCheckbox" />
            </td>
            <td>
              <input className="mt-3" id="otherEmail" name="otherEmail" type="text" placeholder="Other Email" />
            </td>
          </tr>
        </table>
      </div>

      <div className="mb-8">
        <label className="label-light" htmlFor="message">
          Message:
        </label>
        <div>
          <textarea
            id="message"
            name="message"
            className="w-100 h-200p"
            onChange={e => handleMessageChange(e)}
          ></textarea>
        </div>
      </div>

      <button className="button w-100 mt-6 mb-4" type="submit">
        Email Report Metrics
      </button>
    </form>
  );
}

export default EmailReportMetricsForm;
