import { useMutation } from '@tanstack/react-query';
import { useLocationsStore } from '../useLocationsStore';
import { FIVE_SECONDS, showToast, TOAST_ERROR, TOAST_SUCCESS } from 'lib/utils';
import { makeFindMonthlyDatarows } from 'main/factories/usecases/reports/remote-find-montly-data-rows';
import { ReportRow } from '@hone-automation/common';

export const useFindMonthlyDataRows = (currentReport: HoneReportSummary | undefined, rowData: any, date: any) => {
  const currentLocation = useLocationsStore.getState().currentLocation;
  return useMutation({
    mutationFn: () => {
      const findMonthlyService = makeFindMonthlyDatarows();
      return findMonthlyService.findMonthlyDataRows({
        sectionName: rowData.title,
        locationId: currentLocation!.id,
        year: currentReport!.startDateText.substr(0, 4),
        month: date.start,
      }) as Promise<ReportRow[]>;
    },
    onError: () => {
      showToast('Error while updating request', TOAST_ERROR, FIVE_SECONDS);
    },
  });
};
