import { cloneDeep, find } from 'lodash';
import { format, parse } from 'date-fns';
import { formatInTimeZone } from 'date-fns-tz';

import { Tooltip, Whisper } from 'rsuite';

function extractRow({ title, data, level, display }: NestedSection): FlatDatatableRow {
  return { title, data, level, display };
}

function collectRows(sections: NestedSection[]): FlatDatatableRow[] {
  const cloneSections = cloneDeep(sections);
  const rows = [];

  // collect rows from nested sections
  while (cloneSections.length > 0) {
    const section = cloneSections.shift();

    if (section) {
      const row = extractRow(section);

      if (!row.display) {
        // deprecated behavior to clear row data for those we don't want to render cells, prefer display flag instead
        // FIXME magic strings
        const keepTitles = ['total', 'gross', 'net', 'prime', 'kitchen'];
        if (row.level === 0 && !find(keepTitles, (title: string) => row.title.toLowerCase().startsWith(title))) {
          row.label = true;
        }
      }

      rows.push(row);
      if (section.sections.length > 0) cloneSections.unshift(...section.sections);
    }
  }

  return rows;
}

const groupedByMonth = (data: any, monthly = false, customKey = 'Custom') => {
  // Grouping data by year or year-month
  const grouped = data.reduce((acc: any, obj: any) => {
    if (obj.startDateText.hasOwnProperty('limit')) {
      if (!acc['Custom']) {
        acc['Custom'] = [];
      }
      acc['Custom'].push(obj);
      return acc;
    }

    const date = parse(obj.startDate.split('T')[0], 'yyyy-MM-dd', new Date());
    const _format = monthly ? 'yyyy' : 'MMM yyyy';

    const yearMonth = format(date, _format);

    if (!acc[yearMonth]) {
      acc[yearMonth] = [];
    }

    acc[yearMonth].push(obj);
    return acc;
  }, {});

  // Sorting the keys (year or year-month) in descending order
  const sortedKeys = Object.keys(grouped).sort((a, b) => {
    // Check if any of the keys is the custom key
    if (a === customKey) return -1;
    if (b === customKey) return 1;

    // Parsing the keys as dates for comparison
    const dateA = monthly ? new Date(`${a}-01`) : new Date(a);
    const dateB = monthly ? new Date(`${b}-01`) : new Date(b);
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return dateB - dateA;
  });

  // Creating a new object with sorted data
  const sortedGrouped = {};
  sortedKeys.forEach(key => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    sortedGrouped[key] = grouped[key];
  });

  return {
    sortedKeys,
    data: sortedGrouped,
  };
};

const DATE_FORMAT = 'MM-dd-yyyy';

function formatDateReport(date: string | undefined) {
  if (!date) return;
  return formatInTimeZone(new Date(`${date}T00:00:00`), 'America/New_York', DATE_FORMAT);
}

const getTransformTimeFrame = (reportTimeframe: HoneReportTimeframe) => {
  const timeframes = { Weekly: 'Week', Monthly: 'Month', Quarterly: 'Quarter', 'YTD Monthly': 'Year' };
  return timeframes[reportTimeframe as keyof typeof timeframes];
};

const convertTimeframeToReportType = (timeframe: HoneReportTimeframe | undefined) => {
  if (!timeframe) return;
  if (timeframe === 'YTD' || timeframe === 'YTD Monthly') return 'income-statement';
  return timeframe.toLowerCase();
};

const getPrintedHeaderDate = (
  headerFormatted: string,
  compareLocations: string | null,
  returnJustLabel: boolean = false
) => {
  if (headerFormatted.includes('(') && compareLocations && !returnJustLabel) {
    return (
      <Whisper
        trigger="hover"
        placement="top"
        controlId={`${headerFormatted}-id`}
        speaker={<Tooltip>{headerFormatted}</Tooltip>}
      >
        <span className="cell_header_location">{headerFormatted.split('(')[0]}</span>
      </Whisper>
    );
  }

  return headerFormatted;
};

export {
  collectRows,
  groupedByMonth,
  formatDateReport,
  DATE_FORMAT,
  getTransformTimeFrame,
  convertTimeframeToReportType,
  getPrintedHeaderDate,
};
