import { SyntheticEvent, useEffect, useState } from 'react';
import Modal from 'react-modal';
import { confirmAlert } from 'react-confirm-alert';
import classNames from 'classnames';

import EmailReportMetricsForm from 'components/EmailReportMetricsForm';
import { IconFactCheck, IconTrashCan, IconValidate } from 'components/Icons';
import { trackError } from 'lib/analytics';
import { dismissToast, FIVE_SECONDS, showToast, TOAST_DEFAULT, TOAST_SUCCESS } from 'lib/utils';
import { HoneReportTypes } from '../../../../../constants';

import { SlidingPane } from 'components/SlidingPane';
import ValidationReportResults from 'components/ValidationReportResults';
import { getAuditReport, useReportsStore, deleteReport } from 'hooks/useReportsStore';
import { useQueryState } from 'hooks/useQueryState';
import { useNavigate } from 'react-router-dom';
import Tooltip from 'rsuite/Tooltip';
import Whisper from 'rsuite/Whisper';
import { useHoneLocationUsers } from '../../../../../components/HoneLocationUsers';
import { Can } from '@casl/react';
import { useActiveReportStore } from '../../../../../hooks/useActiveReportStore';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useLocationsStore } from 'hooks/useLocationsStore';

interface FloatingAdminMenuProps {
  locationId: string | undefined;
  selectedReportId: string;
  selectedReportType: HoneReportType;
}

export default function FloatingAdminMenu({
  locationId,
  selectedReportId,
  selectedReportType,
}: FloatingAdminMenuProps) {
  const [validation] = useQueryState('validate');
  const { enableNewPlReportModal } = useFlags();
  const [dateRange] = useQueryState('dateRange');
  const currentLocation = useLocationsStore(state => state.currentLocation);

  const { currentLocationAbility } = useHoneLocationUsers();
  const navigateTo = useNavigate();
  const [auditMode, setAuditMode] = useQueryState('audit');
  const [modalOpenEmailMetrics, setModalOpenEmailMetrics] = useState(false);

  const validationReportData = useActiveReportStore(state => state.currentReport?.validation);

  const [showValidationPane, setShowValidationPane] = useState<boolean>(false);

  useEffect(() => {
    if (validation) {
      setShowValidationPane(true);
    }
  }, [validation]);

  const handleCloseEmailReportMetricsModal = () => {
    setModalOpenEmailMetrics(false);
  };

  function toggleValidate() {
    setShowValidationPane(false);
  }

  const [validateReportError, setvalidateReportError] = useState<string | null>(null);

  const handleValidateReport = async (e: SyntheticEvent<HTMLButtonElement>) => {
    const searchParams = new URLSearchParams(window.location.search);
    const validate = searchParams.get('validate');
    if (validate) {
      setShowValidationPane(true);
      return;
    }
    searchParams.set('validate', 'true');
    const newSearch = searchParams.toString();

    navigateTo({
      search: newSearch,
    });
  };

  const handleDeleteReport = async () => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="alert-ui">
            <h3 className="text-center">Are you sure you want to delete this report?</h3>
            <button className="button ReportControls-button mr-2" onClick={onClose}>
              Cancel
            </button>
            <button
              className="button ReportControls-button-alert"
              onClick={() => {
                onClose();
                const toastId = showToast(
                  'Deleting report, will take a moment to complete',
                  TOAST_DEFAULT,
                  FIVE_SECONDS
                );
                deleteReport(selectedReportId)
                  .then(() => {
                    dismissToast(toastId);
                    showToast('Report deleted', TOAST_SUCCESS, FIVE_SECONDS);
                    setTimeout(() => {
                      navigateTo(`/app/location/${locationId}`, { replace: true });
                    }, 2000);
                  })
                  .catch((error: any) => {
                    trackError({ error: error as Error });
                  });
              }}
            >
              Delete Report
            </button>
          </div>
        );
      },
    });
  };

  const toggleAuditMode = () => {
    const nextState = auditMode === 'true' ? 'false' : 'true';
    setAuditMode(nextState);
    return nextState;
  };

  const handleAuditReport = async (toggle = true) => {
    let next: 'true' | 'false' = 'false';
    if (toggle) {
      next = toggleAuditMode();
    }
    if (!toggle || next === 'true') {
      await getAuditReport(selectedReportId);
    } else {
      useReportsStore.setState({ auditReport: null });
    }
  };

  useEffect(() => {
    if (auditMode === 'true') {
      handleAuditReport(false);
    }
  }, []);

  return (
    <>
      <Modal id="goal-modal" isOpen={modalOpenEmailMetrics} onRequestClose={handleCloseEmailReportMetricsModal}>
        <div className="d-flex flex-column">
          <EmailReportMetricsForm
            locationId={locationId}
            reportId={selectedReportId}
            onSuccess={handleCloseEmailReportMetricsModal}
          />
          <button className="button button_link" onClick={handleCloseEmailReportMetricsModal} type="button">
            Cancel
          </button>
        </div>
      </Modal>
      <div className="table_header_button_group">
        <span>ADMIN</span>

        <div className="table_header_button_group__actions">
          {/* {selectedReportType === HoneReportTypes.PLComparison && (
            <Whisper
              placement="top"
              controlId="control-id-hover"
              trigger="hover"
              speaker={<Tooltip>Email report</Tooltip>}
            >
              <button className="table_header_button" title="Email report" onClick={handleOpenEmailReportMetricsModal}>
                <IconEmail />
              </button>
            </Whisper>
          )} */}
          {selectedReportType !== HoneReportTypes.ApAging && !dateRange && (
            <Can I="manage" a="all" ability={currentLocationAbility}>
              <Whisper
                placement="top"
                controlId="control-id-hover"
                trigger="hover"
                speaker={<Tooltip>Delete report</Tooltip>}
              >
                <button className="table_header_button" title="Delete report" onClick={handleDeleteReport}>
                  <IconTrashCan />
                </button>
              </Whisper>
            </Can>
          )}
          <Whisper
            placement="top"
            controlId="control-id-hover"
            trigger="hover"
            speaker={<Tooltip>Validate report</Tooltip>}
          >
            <button
              className="table_header_button"
              title="Validate report"
              type="button"
              onClick={handleValidateReport}
            >
              <IconValidate />
            </button>
          </Whisper>
          {selectedReportType === HoneReportTypes.PLComparison && !dateRange && (
            <Whisper
              placement="top"
              controlId="control-id-hover"
              trigger="hover"
              speaker={<Tooltip>Audit report</Tooltip>}
            >
              <button
                className={classNames('table_header_button', {
                  table_header_button__active: auditMode === 'true',
                })}
                data-testid="report-audit-report-button"
                title="Audit report"
                type="button"
                onClick={() => handleAuditReport()}
              >
                <IconFactCheck />
              </button>
            </Whisper>
          )}
        </div>
      </div>
      <SlidingPane
        onRequestClose={toggleValidate}
        title={`Validation results`}
        width={'75%'}
        isOpen={showValidationPane}
      >
        {validateReportError && <span>{validateReportError}</span>}
        {validationReportData && <ValidationReportResults reportData={validationReportData} />}
      </SlidingPane>
    </>
  );
}
